import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import SEO from '../components/SEO'

const IndexPage = props => {
  const pageSlug = 'home'

  useEffect(() => {
    navigate(`/netflix-deliverable-naming`)
  })

  return (
    <>
      <SEO location={props.location} pageSlug={pageSlug} />
      <div className="container-wide">
        {/* <div className="page-head__wrapper">
          <h1 className="page-head">Naming Convention Tools</h1>
        </div>
        <div style={{ height: '70vh' }}>
          <p>Index Page</p>
        </div> */}
      </div>
    </>
  )
}

export default IndexPage
